@font-face {
  font-family: "GT Walsheim Pro";
  src: local("GT Walsheim Pro Regular"), local("GT-Walsheim-Pro-Regular"),
    url("../assets/font/GTWalsheimPro-Regular.woff2") format("woff2"),
    url("../assets/font/GTWalsheimPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: local("GTWalsheimProBold"), local("GTWalsheimProBold"),
    url("../assets/font/GTWalsheimProBold.woff2") format("woff2"),
    url("../assets/font/GTWalsheimProBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Pro";
  src: local("GTWalsheimProMedium"), local("GTWalsheimProMedium"),
    url("../assets/font/GTWalsheimProMedium.woff") format("woff"),
    url("../assets/font/GTWalsheimProMedium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

* {
  font-family: "GT Walsheim Pro";
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.m-0{
  margin: 0px;
}
.p-0{
  padding: 0px;
}
.txt-capitalize{
  text-transform: capitalize;
}
.d-inline-block{
  display: inline-block;
}
.back-grad-title {
  padding-top: 200px;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
  background-image: linear-gradient(to right, #c1c1f3, #5960ff);
  border-bottom-left-radius: 65px;
  border-bottom-right-radius: 65px;

  @media only screen and (max-width: 767px) {
    padding-top: 170px;
    padding-bottom: 60px;
  }

  @media only screen and (max-width: 480px) {
    padding-bottom: 30px;
    padding-top: 150px;
  }

  .pricing-main-title {
    font-size: 65px;
    text-align: center;
    color: white;

    @media only screen and (max-width: 767px) {
      font-size: 40px;
    }

    @media only screen and (max-width: 480px) {
      font-size: 30px;
    }
  }
}

.CardField-expiry {
  transform: none !important;
}

.hl_cta_wrap {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 12px 20px;
  border: 1px solid currentColor;
  border-radius: 14px;
  transition: 0.5s ease-in-out;
  z-index: 1;
  background-color: transparent;
  color: #1664fa;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff !important;
  }
}

.hl_cta_wrap {
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.5s ease-in-out;
    z-index: -1;
  }
}

.hl_cta_wrap {
  &::after {
    left: -50px;
    border-left: 50px solid transparent;
    border-top: 80px solid #5960ff;
    transform: translateX(100%);
  }

  &::before {
    right: -50px;
    border-right: 50px solid transparent;
    border-bottom: 80px solid #5960ff;
    transform: translateX(-100%);
  }

  &:hover {
    color: #ffffff;

    &::after {
      transform: translateX(49%);
    }

    &::before {
      transform: translateX(-49%);
    }
  }
}

.errorInput {
  border-color: red;
}

.hl_cta_wrap-white {
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.5s ease-in-out;
    z-index: -1;
  }
}

.hl_cta_wrap-white {
  &::after {
    left: -50px;
    border-left: 50px solid transparent;
    border-top: 80px solid #ffffff;
    transform: translateX(100%);
  }

  &::before {
    right: -50px;
    border-right: 50px solid transparent;
    border-bottom: 80px solid #fff;
    transform: translateX(-100%);
  }

  &:hover {
    color: #1664fa !important;

    &::after {
      transform: translateX(49%);
    }

    &::before {
      transform: translateX(-49%);
    }
  }
}

:where(.css-dev-only-do-not-override-k7429z).ant-notification
  .ant-notification-notice
  .ant-notification-notice-message {
  height: max-content;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.overly-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1905px;
  width: 100%;
  min-height: 100%;
  height: 800px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  top: 0;
  left: 0;
}

.anticon.anticon-close-circle {
  display: none;
}
.session_expired_modal {
  .ant-modal-body {
    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-body {
        justify-content: space-around;
        span {
          display: none;
        }
      }
    }
  }
}
.session-Model {
  .session-modal-logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;

    .bd-logo {
      border: 1px solid;
      border-radius: 50%;
      border-color: #ff6163;
      height: 77px;
      width: 77px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      svg {
        font-size: 70px;
        color: #ff6163;
      }
    }
  }

  .session-modal-text {
    text-align: center;

    h3 {
      margin-bottom: 0;
      font-size: 30px;
    }

    p {
      font-size: 16px;
    }
  }

  .session-modal-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 35px;
  }
}

.errorFelid {
  border-color: red !important;
}

.overly-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1905px;
  width: 100%;
  min-height: 100%;
  height: 800px;
  background-color: rgba(255, 255, 255, 0.425);
  z-index: 100;
  top: 0;
  left: 0;
}

// New Color Theme Css

.theme-clr-modal {
  .login-modal-title {
    background-color: #5960ff !important;
  }
}

.theme-clr-modal {
  .wrap-top-modal {
    .body-modal {
      .submit-btn-modal {
        .hl_cta_wrap {
          color: #5960ff;
          border-color: #5960ff;
          &::before {
            border-bottom-color: #5960ff;
          }
          &::after {
            border-top-color: #5960ff;
          }
        }
      }
    }
  }
}

.theme-clr-modal {
  .hl_cta_wrap {
    color: #5960ff;
    border-color: #5960ff;
    &::before {
      border-bottom-color: #5960ff;
    }
    &::after {
      border-top-color: #5960ff;
    }
  }
}

// Direction rtl Css

.theme_dir__rtl {
  .homePage-main-section {
    .bg-grad-section {
      .df-first-section {
        .right-contant {
          right: auto;
          left: 14%;
          bottom: -10px;
        }
      }
    }
  }
  .header-main-section {
    .header {
      .header-df {
        .header-menu-nd-login {
          .responsive-header-btn {
            margin-left: 0px !important;
          }
          .header-lang-drop {
            margin-left: 20px;
            margin-right: 20px;
          }
        }
      }
    }
  }
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .input-suffix {
              right: auto !important;
              left: 0px !important;
            }
            .desktop-input-nd {
              border-left: unset;
              border-right: 1px solid gray;
              border-radius: 50px 0px 0px 50px;
            }
            .desktop-input {
              .flag-dropdown {
                .selected-flag {
                  padding: 0px 12px 0px 0px;
                }
              }
            }
          }
        }
      }
    }
  }
  .dashboard-main-section {
    .container {
      .dashboard-find-number {
        .dashboard-locate {
          input {
            text-align: right;
            padding-right: 95px;
            padding-left: 0px;
          }
          .input-suffix {
            left: 5px;
            right: auto;
          }
          .selected-flag {
            border-radius: 0px 15px 15px 0px;
            padding: 0 40px 0 30px;
          }
          .flag-dropdown {
            border-radius: 0px 15px 15px 0px;
          }
        }
        label {
          span {
            &:last-child {
              margin-right: 1rem;
              margin-left: 0px;
            }
          }
        }
      }
      .setting-find-number {
        .setting-billing-heading {
          span {
            &:last-child {
              margin-right: 1rem;
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}
.theme_dir__rtl {
  .bg-grad-section {
    .how-work-section {
      .how-work-container {
        .how-work-body {
          .how-work-desc-sections {
            .box {
              .how-work-contant {
                .left-contant {
                  .how-work-desc {
                    text-align: right;
                  }
                  .how-work-box-title {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .choose-tracelo-section {
    .choose-tracelo-container {
      .wrap-choose-tracelo {
        .choose-tracelo-under-section {
          .df-wrap-service {
            .choose-tracelo-box {
              .choose-tracelo-part {
                .choose-tracelo-box-desc {
                  p {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .bg-grd-section {
    .frequently-asked-question {
      .question-title {
        span {
          text-align: right;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .theme_dir__rtl {
    .header-main-section {
      .header {
        .header-df {
          .header-main-logo {
            padding-left: 0px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}

// Modal Direction rtl Css

.theme_dir__rtl {
  .login-modal-section {
    .wrap-top-modal {
      .body-dash-modal {
        .dashboard-find-number {
          .dashboard-locate {
            input {
              text-align: right;
              padding-right: 80px;
              padding-left: 0px;
            }
            .flag-dropdown {
              border-radius: 0px 15px 15px 0px;
            }
            .selected-flag {
              padding: 0px 40px 0px 30px;
              background: transparent;
            }
          }
          label {
            span {
              &:last-child {
                margin-right: 1rem;
                margin-left: 0px;
              }
            }
          }
        }
        .dashboard-message {
          label {
            span {
              &:last-child {
                margin-right: 1rem;
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}

// Faqs Css

@media only screen and (max-width: 767px) {
  .bg-grd-section {
    .frequently-asked-question {
      .question-collapse {
        .collapse-box {
          padding: 15px 10px;
          .collapse-box-df-title {
            .collapse-box-title {
              p {
                font-size: 16px !important;
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .bg-grad-section {
    .home-special-offer-section {
      .home-special-offer {
        .specialOffer-container {
          .specialOffer-title {
            h3 {
              text-align: right;
            }
            p {
              text-align: right;
            }
          }
          .specialOffer-desc {
            text-align: right;
          }
        }
      }
    }
  }
}

.theme_dir__rtl {
  .react-tel-input {
    .country-list {
      .flag {
        margin-left: 7px;
        margin-right: 0px;
      }
    }
  }
}
.react-tel-input {
  .country-list {
    .country-name {
      margin-right: 6px;
    }
  }
}

// Track Page RTL Css

.theme_dir__rtl {
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .right-contant {
          .banner-img {
            img {
              transform: rotateY(180deg);
            }
          }
          right: auto;
          left: 0px;
        }
      }
    }
  }
  .header-main-section {
    .header {
      .header-df {
        .header-menu-nd-login {
          margin-right: 0px;
          margin-left: 100px;
        }
        .header-main-logo {
          padding-left: 0px;
          padding-right: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .theme_dir__rtl {
    .header-main-section {
      .header {
        .header-df {
          .header-menu-nd-login {
            margin-right: 0px !important;
            margin-left: 20px !important;
          }
          .header-main-logo {
            padding-left: 0px !important;
            padding-right: 20px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .theme_dir__rtl {
    .header-main-section {
      .header {
        .header-df {
          .header-menu-nd-login {
            .header-lang-drop {
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .theme_dir__rtl {
    .bg-grd-section {
      .frequently-asked-question {
        .question-collapse {
          .collapse-box {
            padding: 15px 10px;
          }
        }
      }
    }
    .collapse-box-icon {
      margin-left: 0px !important;
      margin-right: 15px !important;
    }
  }
}

.theme_dir__rtl {
  .pricing-main-section {
    .container {
      .home-special-offer-section {
        .home-special-offer {
          .specialOffer-container {
            .specialOffer-title {
              p {
                text-align: right;
              }
            }
            .specialOffer-desc {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
  position: fixed !important;
  width: 100% !important;
}

.theme_dir__rtl {
  .homePage-main-section {
    .Locate-Any-Phone {
      .wid-home-banner {
        .df-first-section {
          .left-contant {
            .head-input {
              overflow: unset;
            }
          }
        }
      }
    }
  }
  .Locate-Any-Phone {
    .wid-home-banner {
      .df-first-section {
        .left-contant {
          .head-input {
            .react-tel-input {
              border-radius: 0px 50px 50px 0px;
              > input {
                border-radius: 0px 50px 50px 0px;
                padding-left: 0px;
                padding-right: 45px;
                text-align: right;
                opacity: 1 !important;
                &::placeholder{
                  color: #000000 !important;
                }
              }
              .flag-dropdown {
                padding: 0 2px;
              }
            }
          }
        }
      }
    }
  }
}

// New Extrenal Css
.theme_dir__rtl.login_btn__wrapper {
  button.ant-modal-close {
    left: 5% !important;
    right: auto !important;
    width: auto !important;
  }
}
.theme_dir__rtl.location_modal {
  button.ant-modal-close {
    left: 5% !important;
    right: auto !important;
    width: auto !important;
  }
}
.theme_dir__rtl.ant-dropdown {
  ul.ant-dropdown-menu {
    li.ant-dropdown-menu-item {
      span.ant-dropdown-menu-title-content {
        a {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
.theme_dir__rtl {
  .ant-modal-body {
    .login-modal-section {
      .wrap-top-modal {
        .google-button-login {
          button.login-with-google-btn {
            background-position: calc(100% - 15px) center;
          }
        }
      }
    }
  }
}
button.ant-modal-close {
  &:hover {
    background-color: transparent !important;
  }
}

.theme_dir__rtl {
  .back-grad-title {
    background-image: linear-gradient(to left, #c1c1f3, #5960ff);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.theme_dir__rtl {
  .dashboard-main-section {
    .container {
      .dashboard-find-number {
        .dashboard-locate {
          input.search-box {
            padding-right: 10px !important;
          }
        }
      }
    }
  }
}
.theme_dir__rtl.ant-dropdown-menu {
  li.ant-dropdown-menu-item last-of-type {
    a {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.theme_dir__rtl {
  .dashboard-main-section {
    .container {
      .dashboard-find-number {
        .dashboard-locate {
          .selected-flag {
            z-index: 999;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 590px) and (max-width: 675px) {
  .choose-tracelo-section {
    .choose-tracelo-container {
      .wrap-choose-tracelo {
        .choose-tracelo-under-section {
          .df-wrap-service {
            .choose-tracelo-box {
              .choose-tracelo-part {
                .choose-tracelo-box-title {
                  p {
                    text-align: left;
                  }
                }
              }
              .choose-box-one {
                .one-p-choose {
                  p {
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .df-footer {
    .footer-right-contan {
      padding-left: 0px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .login-modal-section {
    .reset-modal-title {
      width: max-content;
    }
  }
}

.theme_dir__rtl button.strip__gpay_contemt {
  direction: ltr;
}

.theme_dir__rtl .google-pay-content .bottom-content .df-icon-secure .icon img {
  transform: rotate(180deg);
}